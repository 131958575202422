import * as bootstrap from 'bootstrap';
import { pannellum } from './pannellum';
import Player from '@vimeo/player';

// Define the interface of a scene.
interface Scene {
  type: 'equirectangular';
  hotSpotDebug?: boolean; // This should only be used in development for finding the correct yaw and pitch.
  panorama: URL;
  hotSpots: Hotspot[];
  autoLoad: true;
}
// Define the interface of a hotspot.
interface Hotspot {
  type: 'info' | 'scene';
  id: string;
  pitch: number;
  yaw: number;
  text: string;
  cssClass: string;
  clickHandlerFunc: Function;
  clickHandlerArgs: {
    afterCompletion?: {
      create?: string[];
      view?: string;
      hide?: boolean;
      load?: string;
    };
    startingAction?: number; // Only if there is an action to start with.
    id: string;
  };
  sceneId?: string; // Only when it's a scene hotspot.
}

// Creating the action interface, an action can be of three types: text, video or question.
interface Action {
  type: 'text' | 'video' | 'question' | 'redirect';
  next?: number;
}

// Creating the text action interface.
interface TextAction extends Action {
  type: 'text';
  content: string;
  img?: string;
}

// Creating the video action interface.
interface VideoAction extends Action {
  type: 'video';
  vimeoId: number;
}

// Creating the question action interface, a question can have multiple answers, each answer can have either a next action or a scene to load.
interface QuestionAction extends Action {
  type: 'question';
  question: string;
  answers:
    | {
        answer: string;
        next?: number;
      }[]
    | {
        answer: string;
        scene?: string;
      }[];
}

// Creating the redirect action interface.
interface RedirectAction extends Action {
  type: 'redirect';
  url: string;
}
const startingScene : string = 'Start';
const buttonText : string = 'Sluit af';

      document.getElementById('website').addEventListener('click', function() {
        window.open('https://verbi.com/', '_blank');
        
      });
    const scenes : { [sceneName: string]: Scene } = {"Start":{"type":"equirectangular","panorama":new URL('../Assets/360/Start.webp',import.meta.url),"hotSpots":[{"id":"Start_1","cssClass":"custom-hotspot Start_1","clickHandlerFunc":hotspotClickHandler,"clickHandlerArgs":{"id":"Start_1","afterCompletion":{"hide":true},"startingAction":0},"pitch":0,"yaw":0,"type":"info","text":"<b>Welkom bij Verbi</b>"}],"autoLoad":true,"yaw":0,"pitch":0}};
        const hotspots : {[ID : string] : Hotspot} = {"Start_1":{"id":"Start_1","cssClass":"custom-hotspot Start_1","clickHandlerFunc":hotspotClickHandler,"clickHandlerArgs":{"id":"Start_1","afterCompletion":{"hide":true},"startingAction":0},"pitch":0,"yaw":0,"type":"info","text":"<b>Welkom bij Verbi</b>"}};
        const actions : (TextAction | VideoAction | QuestionAction | RedirectAction)[] = [{"type":"text","content":"<h1 id=\"help-ons-met-de-volgende-informatie\">Help ons met de volgende informatie</h1>\n<div style=\"width: 100%; max-width: 640px; margin: auto;\">\n  <iframe src=\"https://docs.google.com/forms/d/e/1FAIpQLScEAPJ7RXtlulmAkSrfcBfDSm0jOl9-HMYvTvXeAok88JMJTw/viewform?embedded=true\" style=\"width: 100%; height: 598px; border: 0; margin: 0;\" frameborder=\"0\" allowfullscreen=\"\">\n      Loading…\n  </iframe>\n</div>\n","next":1},{"type":"video","vimeoId":1037867365,"next":2},{"type":"text","content":"<h1 id=\"hartelijk-dank\">Hartelijk dank</h1>\n<p>Hartelijk dank voor je deelname!</p>\n"}];
let viewer;

async function initiate_viewer() {
  viewer = await pannellum.viewer('panorama', {
    hfov: 120,
    autoLoad: false,
    touchPanSpeedCoeffFactor: 2.5, // Increases the rotation speed on touch devices (default: 1.0).
    friction: 0.05, // Decreases the friction, so the panorama keeps spinning longer (default: 0.15).
    showControls: false,
    scenes: scenes,
  });

  // Enable loading the scene from URL
  const path = window.location.pathname;
  // If the path is not the root path, load the scene.
  if (path !== '/') {
    try {
      viewer.loadScene(path.slice(1));
    } catch (error) {
      // console.log(error);
      console.log('Scene not found or another error occured.');
    }
  } else {
    viewer.loadScene(startingScene);
  }

  // On the load event of the viewer, check if the window width is smaller than 768px, if it is, zoom in completely.
  let hasEventListenerAdded = false; // Track if the load event has already been added

  document.addEventListener('click', function handleClick() {
    if (!hasEventListenerAdded) {
      hasEventListenerAdded = true; // Prevent adding multiple event listeners

      viewer.on('load', function () {
        if (window.innerWidth < 768) {
          viewer.setHfov(0);
        }
        const currentScene = viewer.getScene();
        const url = new URL(window.location.href);
        url.pathname = `/${currentScene}`;
        window.history.pushState({}, '', url.toString());
        // If the posthog object exists, capture the scene load event.
        if (typeof posthog !== 'undefined') {
          posthog.capture('$pageview');
        }
      });

      // Remove the click listener to avoid further executions
      document.removeEventListener('click', handleClick);
    }
  });

  let fullscreenMode: boolean = false;
  document
    .getElementById('fullscreen')
    ?.addEventListener('click', function (e) {
      const fullscreenSvg = `<svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 -960 960 960" width="40"><path d="M120-120v-200h80v120h120v80H120Zm520 0v-80h120v-120h80v200H640ZM120-640v-200h200v80H200v120h-80Zm640 0v-120H640v-80h200v200h-80Z"/></svg>`;
      const fullscreenOffSvg = `<svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 -960 960 960" width="40"><path d="M240-120v-120H120v-80h200v200h-80Zm400 0v-200h200v80H720v120h-80ZM120-640v-80h120v-120h80v200H120Zm520 0v-200h80v120h120v80H640Z"/></svg>`;
      let fullscreenButton = document.getElementById('fullscreen');
      if (fullscreenButton) {
        fullscreenButton.innerHTML = fullscreenMode
          ? fullscreenSvg
          : fullscreenOffSvg;
      }
      viewer.toggleFullscreen();
      fullscreenMode = !fullscreenMode;
    });
  document.getElementById('home').addEventListener('click', function (e) {
    viewer.loadScene(startingScene);
  });
  document.getElementById('bolt').addEventListener('click', function (e) {
    let conversionOptions = document.querySelector('.conversion-options');
    conversionOptions.classList.toggle('open');
  });
  document.getElementById('compass')?.addEventListener('click', function (e) {
    let compass = document.getElementById('compass');
    const compassOffSvg = `<svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 -960 960 960" width="40"><path d="M596-478 478-596l222-104-104 222ZM791-55l-91-91q-49 32-104.5 49T480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-60 17-115.5T146-700l-91-91 57-57 736 736-57 57Zm23-205-58-58q22-38 33-78.5t11-83.5q0-133-93.5-226.5T480-800q-43 0-83.5 11T318-756l-58-58q49-32 104.5-49T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 60-17 115.5T814-260ZM537-537ZM423-423Zm57 263q43 0 83.5-11t78.5-33L482-364 260-260l102-224-158-158q-22 38-33 78.5T160-480q0 133 93.5 226.5T480-160Z"/></svg>`;
    const compassSvg = `<svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 -960 960 960" width="40"><path d="m263-263 290-143 143-290-290 143-143 290Zm217-177q-17 0-28.5-11.5T440-480q0-17 11.5-28.5T480-520q17 0 28.5 11.5T520-480q0 17-11.5 28.5T480-440Zm0 360q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480q0-83 31.5-156t86-127Q252-817 325-848.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 82-31.5 155T763-197.5q-54 54.5-127 86T480-80Zm0-60q142 0 241-99.5T820-480q0-142-99-241t-241-99q-141 0-240.5 99T140-480q0 141 99.5 240.5T480-140Zm0-340Z"/></svg>`;
    let orientationState = viewer.isOrientationActive();
    if (compass) {
      compass.innerHTML = orientationState ? compassSvg : compassOffSvg;
    }
    if (orientationState == true) {
      viewer.stopOrientation();
    } else {
      viewer.startOrientation();
    }
  });

  // Add a loading spinner.
  viewer.on('scenechange', function () {
    var div = document.createElement('div');
    div.className = 'loading-spinner';
    document.body.appendChild(div);
    function onload() {
      document.querySelector('.loading-spinner').remove();
      viewer.off('load', onload);
    }
    viewer.on('load', onload);
  });

  // @starting_hotspot_placeholder
}

initiate_viewer();

// This function is called when the hotspot is clicked. It handles four things, the startingAction, and the view, create and hide options.
function hotspotClickHandler(
  clickEvent: PointerEvent,
  hotspotArguments: Hotspot['clickHandlerArgs'],
) {
  if (
    !clickEvent.target?.classList.contains(`${viewer.getScene()}_0`) &&
    typeof posthog !== 'undefined'
  ) {
    posthog.capture('User clicked hotspot', {
      name: clickEvent.target?.innerText,
    });
  }
  // Create a new bootstrap modal.
  let modal = new bootstrap.Modal('#actionModal');
  // Check if the hotspot has a startingAction.
  if (hotspotArguments['startingAction'] != undefined) {
    if (hotspotArguments['startingAction']['type'] != 'redirect') {
      modalParser(actions[hotspotArguments['startingAction']]);
      modal.toggle();
    } else {
      // Open in a new tab:
      window.open(hotspotArguments['startingAction']['url'], '_blank');
    }
  }
  // In this order, check the arguments['afterCompletion'] for a create, hide, view and load value.
  // Then add an event listener that runs once to the modal, if the modal is hidden, run the afterCompletion functions.
  if (hotspotArguments['afterCompletion']) {
    let actionModal = document.getElementById('actionModal');
    let afterCompletion = hotspotArguments['afterCompletion'];
    actionModal.addEventListener(
      'hidden.bs.modal',
      function () {
        if (afterCompletion['create'] != undefined) {
          // For all the hotspots in the create array, add them to the viewer.
          afterCompletion['create'].forEach((hotspot) => {
            viewer.addHotSpot(hotspots[hotspot]);
          });
        }
        if (afterCompletion['hide'] != undefined) {
          viewer.removeHotSpot(hotspotArguments.id);
        }
        if (afterCompletion['view'] != undefined) {
          let hotspotToView = hotspots[afterCompletion['view']];
          viewer.lookAt(hotspotToView.pitch, hotspotToView.yaw);
        }
        if (afterCompletion['load'] != undefined) {
          viewer.loadScene(afterCompletion['load']);
        }
      },
      { once: true },
    );
  }
}

// This function receives the action object and modifies the modal content.
async function modalParser(
  action: TextAction | VideoAction | QuestionAction | RedirectAction,
) {
  let actionModal = document.getElementById('actionModal');
  // Get the #actionModal// If the action object is a Redirect, open the url in a new tab, hide the modal and stop executing the function.
  if (action['type'] == 'redirect') {
    window.open(action['url'], '_blank');
    // If there is an action modal, get the bootstrap modal instance.
    setTimeout(() => {
      let actionModal = document.getElementById('actionModal');
      if (actionModal != null) {
        let modal = bootstrap.Modal.getInstance(actionModal);
        if (modal != null) {
          modal.hide();
        }
      }
    }, 1000);
    return;
  }
  let modalBody = actionModal.querySelector('.modal-body');
  let modalDialog = actionModal.querySelector('.modal-dialog');
  modalBody.innerHTML = '';
  modalBody.classList.remove('video-div', 'modal-lg');
  modalDialog.classList.remove('modal-xl');
  let icon = document.createElement('i');
  icon.classList.add('bi', 'bi-arrow-right-short');
  // We check for the type of the action object.
  switch (action['type']) {
    // If the action object is a text, create a new div with the class text-wrapper and add it to the modal-body.
    case 'text':
      // Add the 'modal-lg' class to the modal-dialog.
      modalDialog.classList.add('modal-lg');
      let textWrapper = document.createElement('div');
      textWrapper.classList.add('text-wrapper');
      modalBody.appendChild(textWrapper);
      // Insert the content of the action object into the textWrapper.
      textWrapper.innerHTML = action['content'];
      // Check if there is an img in the action object.
      if (action['img'] != undefined) {
        // Create a new img element and add it to the textWrapper.
        let img = document.createElement('img');
        img.src = action['img'];
        textWrapper.appendChild(img);
      }
      // Add a button to the modal-body.
      let button = document.createElement('button');
      button.classList.add('btn', 'btn-primary');
      button.innerHTML = buttonText;
      button.appendChild(icon);
      actionModal.querySelector('.modal-body').appendChild(button);
      // Check if the action object has a next property.
      if (action['next'] == undefined) {
        // If the action object doesn't have a next property, add the dismiss attribute to the button.
        button.dataset.bsDismiss = 'modal';
      } else {
        // Add an event listener to the button, which only runs once.
        button.addEventListener(
          'click',
          (event) => {
            modalParser(actions[action['next']]);
          },
          { once: true },
        );
      }
      break;
    case 'video':
      // Adding appropriate classes for styling.
      modalDialog.classList.add('modal-xl');
      modalBody.classList.add('video-div');

      // Make an oEmbed request to Vimeo, and get the aspect ratio. We save it in the variable aspectRatio.
      let response = await fetch(
        `https://vimeo.com/api/oembed.json?url=https://vimeo.com/${action['vimeoId']}`,
      );
      let data = await response.json();
      let aspectRatio = data['width'] / data['height'];
      if (aspectRatio < 1 && window.innerWidth > 768) {
        // Fetch the div with id 'innerContent' and set its max-width to calc(90vh*${aspectRatio}). This is done to make sure the video doesn't overflow the modal.
        let innerContent = document.getElementById('innerContent');
        if (innerContent != null) {
          innerContent.style.maxWidth = `calc(88vh*${aspectRatio})`;
        }
      }
      // Create a button and add it to the modal-body.
      let closeButton = document.createElement('button');
      closeButton.classList.add('btn-close', 'btn-close-white');
      closeButton.dataset.ariaLabel = 'Close';
      // Add a right-arrow icon to the button.
      modalBody.appendChild(closeButton);
      // Create a div with the class video-wrapper and add it to the modal-body.
      let videoWrapper = document.createElement('div');
      videoWrapper.classList.add('video-wrapper');
      modalBody.appendChild(videoWrapper);
      // Create an iframe and add it to the videoWrapper.
      let iframe = document.createElement('iframe');
      // Set the css variable '--aspect-ratio' to the aspectRatio.
      document.documentElement.style.setProperty(
        '--aspect-ratio',
        aspectRatio.toString(),
      );
      // Set the appropriate attributes for the iframe.
      iframe.src = `https://player.vimeo.com/video/${action['vimeoId']}?texttrack=nl&title=0&byline=0&portrait=0&autoplay=1&dnt=1`;
      iframe.allow = 'autoplay';
      iframe.allowFullscreen = true;
      videoWrapper.appendChild(iframe);
      const player = new Player(iframe);
      // Add an event listener to the modal, which only runs once. When the modal is closed, the iframe should be removed.
      actionModal.addEventListener(
        'hidden.bs.modal',
        function () {
          iframe.remove();
          // Fetch the div with id 'innerContent' and remove all styles.
          let innerContent = document.getElementById('innerContent');
          innerContent.removeAttribute('style');
        },
        { once: true },
      );
      // Check if the action object has a next property.
      if (action['next'] == undefined) {
        player.on('ended', function () {
          let modal = bootstrap.Modal.getInstance(actionModal);
          // If the action object doesn't have a next property, close the modal.
          modal.toggle();
        });
        // If the action object doesn't have a next property, add the dismiss attribute to the button.
        closeButton.dataset.bsDismiss = 'modal';
      } else {
        player.on('ended', function () {
          modalParser(actions[action['next']]);
        });
        // Add an event listener to the button, which only runs once.
        closeButton.addEventListener(
          'click',
          (event) => {
            modalParser(actions[action['next']]);
          },
          { once: true },
        );
      }
      break;
    case 'question':
      modalDialog.classList.add('modal-lg');
      // Create a new div with class 'question-wrapper' and add it to the modal-body.
      let questionWrapper = document.createElement('div');
      questionWrapper.classList.add('question-wrapper');
      modalBody.appendChild(questionWrapper);
      // Insert the content of the question into the questionWrapper.
      questionWrapper.innerHTML = action['question'];
      // Create a new form and add it to the questionWrapper.
      let form = document.createElement('form');
      // Add a div with class 'd-grid gap-2' to the form.
      let dGrid = document.createElement('div');
      dGrid.classList.add('d-grid', 'gap-2');
      form.appendChild(dGrid);
      questionWrapper.appendChild(form);
      // Loop over the answers and create a new button for each answer.
      action['answers'].forEach((answer, index) => {
        // Create the input.
        let input: HTMLInputElement = document.createElement('input');
        input.type = 'radio';
        // If the answer has a next value, or a scene value add the value to the input, else set it to no-next.
        if (answer['next'] != undefined) {
          input.value = answer['next'].toString();
        } else if (answer['scene'] != undefined) {
          input.value = answer['scene'];
        } else {
          input.value = 'no-next';
        }
        input.name = 'answer';
        input.classList.add('btn-check', 'form-button');
        input.id = `opt-${index}`;
        input.autocomplete = 'off';
        // Create the label.
        let label = document.createElement('label');
        label.classList.add('btn', 'btn-outline-primary');
        label.htmlFor = `opt-${index}`;
        // Create the icons.
        let icon = document.createElement('i');
        let icon2 = document.createElement('i');
        icon.classList.add('bi', 'bi-arrow-right-short');
        icon2.classList.add('bi', 'bi-check');
        // Create the span.
        let span = document.createElement('span');
        span.innerHTML = answer['answer'];
        // Append the icon and span to the label.
        label.appendChild(icon);
        label.appendChild(icon2);
        label.appendChild(span);
        // Append the input and label to the dGrid.
        dGrid.appendChild(input);
        dGrid.appendChild(label);
      });
      // Create a button and add it to the form.
      let submitButton = document.createElement('button');
      submitButton.classList.add('btn', 'btn-primary');
      submitButton.type = 'submit';
      // Set the visibility of the button to hidden.
      submitButton.style.visibility = 'hidden';
      submitButton.innerHTML = buttonText;
      // Add a right-arrow icon to the button.
      submitButton.appendChild(icon);
      form.appendChild(submitButton);
      // Add an event listener to the form.
      form.addEventListener('submit', (event) => {
        // Prevent the default action of the form.
        event.preventDefault();
        // Get the value of the checked radio button.
        let checked: HTMLInputElement | null = document.querySelector(
          'input[name="answer"]:checked',
        );
        // Read the text inside the span of the checked radio button.
        let answerText = '';
        if (
          checked !== null &&
          checked.nextElementSibling !== null &&
          checked.nextElementSibling.lastElementChild !== null &&
          typeof posthog !== 'undefined'
        ) {
          answerText = checked.nextElementSibling.lastElementChild.innerHTML;
          posthog.capture('User answered question', {
            question: action['question'],
            answer: answerText,
          });
        }

        // Check if the checked radio button has a value.
        if (checked.value == 'no-next') {
          let modal = bootstrap.Modal.getInstance(actionModal);
          // If the checked radio button doesn't have a value, close the modal.
          modal.toggle();
        } else {
          // Check for the type of the next action.
          // If it's a number, call the modalParser function with the next action.
          if (!isNaN(parseInt(checked.value))) {
            modalParser(actions[parseInt(checked.value)]);
          }
          // If it's a string, load the scene.
          else {
            let modal = bootstrap.Modal.getInstance(actionModal);
            modal.toggle();
            viewer.loadScene(checked.value);
          }
          // If the checked radio button has a value, call the modalParser function with the next action.
        }
      });
      // If one of the answer buttons inside the form is clicked, auto submit the form.
      form.addEventListener('click', (event) => {
        if (event.target?.classList.contains('btn-check')) {
          submitButton.click();
        }
      });
      break;
  }
}
